export const API_URL = 'https://api.downtracker.com';
// export const API_URL = 'https://downtracker.local/public';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const ENV = process.env.NODE_ENV;

export const paths = {
    HOME: '/',
    DETAILS_PAGES: id => `/lot/${id}`,
    LOT_FILL_POWER: id => `/lot/${id}/fill`,
    LOT_SPECIES: id => `/lot/${id}/species`,
    LOT_ORIGIN: id => `/lot/${id}/origin`,
    LOT_DOWNTEK: id => `/lot/${id}/downtek`,
    LOT_BRAND: `/lot/id/brand`,
    LOT_CERTIFICATIONS: `/lot/id/certifications`,
    LOGOUT: '/account/logout',
    LOGIN: '/account/login',
    CODE_LOGIN: '/account/login/code',
    REMIND_PASSWORD: '/account/remindPassword',
    RESET_PASSWORD: `/account/resetPassword`,
    SET_PASSWORD: `/account/setPassword`,
    REGISTER: '/account/register',
    ADMIN_DASHBOARD: '/admin/dashboard',

    ADMIN_PRODUCTS: '/admin/products',
    ADMIN_PRODUCTS_ADD: '/admin/products/new',
    ADMIN_PRODUCTS_ADD_DETAILS: '/admin/products/new/details',
    ADMIN_PRODUCTS_EDIT: id => `/admin/products/edit/${id}`,
    ADMIN_DOWN_SOURCE: '/admin/down-source',

    // Currently we use this URL as a hack for images upload. We can create a new source, upload an image to get proper
    // name and ID, and when we have it, we can remove item. Image will stay in the storage and in database.
    ADMIN_DOWN_SOURCE_ADD: '/admin/new-down-source',

    ADMIN_DOWN_SOURCE_REMOVE: id => `/admin/remove-down-source/${id}`,
    ADMIN_DOWN_SOURCE_EDIT: id => `/admin/edit-down-source/${id}`,


    /// po zaoraniu

    ADMIN_PRODUCT_FORM : '/admin/product-form',
    ADMIN_PRODUCT_EDIT_FORM : '/admin/product-edit',
    ADMIN_PRODUCT_EDIT_LIST : '/admin/product-edit-list',
    FRONT_FAQ: '/faq'
};


export const VALIDATION_MESSAGES = {
    maxLength: (max) => `Must be ${max} characters or less`,
    minLength: (min) => `Must be ${min} characters or more`,
    aboveZero: 'Wartość musi być wyższa od zera',
    required: 'This is a required field',
    email: 'Email Address is required.',
    password: 'Hasło musi mieć od 6 do 32 znaków',
    passwordConfirmation: 'Wprowadzone hasła są różne',
    url: 'Niepoprawny link',
    default: 'Invalid field',
    correctCode: 'Nieprawidłowy format numeru',
    dateFormat: 'Nieprawidłowy format daty',
    days:'Minimalna ważność to 30 dni'

}

export const FRONT_DATE_FORMAT = 'DD-MM-YYYY';


export const roleRoutes = {
    ROLE_USER: 'admin',
    ROLE_COMPANY: 'company',
    ROLE_PARTNER_ADMIN: 'partnerAdmin',
    ROLE_PARTNER_EMPLOYEE: 'partnerUser'
};

export const roleRequest = {
    ROLE_ADMIN: 'admin',
    ROLE_COMPANY: 'company',
    ROLE_PARTNER_ADMIN: 'partner',
    ROLE_PARTNER_EMPLOYEE: 'partner'
};

export const errorsRequest = {
    username: 'Nazwa użytkownika',
    address: 'Adres',
    forename: 'Imię',
    surname: 'Nazwisko',
    email: 'Email',
    password: 'Hasło',
    subject: 'Temat',
    body: 'Treść',
    invoice_email: 'Email do faktur',
    recipient: 'Odbiorcy',
    amount: 'Kwota',
    credits_monthly: 'Ilość kredytów',
    newPassword: 'Nowe hasło',
    oldPassword: 'Stare hasło',
    photo: 'Zdjęcie',
    name: "Nazwa",
    credit_value: "Wartość kredytu",
    agreement_start: 'Rozpoczęcie umowy',
    agreement_end: 'Zakończenie umowy'

};
