import { match } from '@/utils/match';

const INITIAL_STATE = {
    userLanguage: false,
    labels: {},
    languages: [],
    dictionaries: {
        currency: [],
        measureUnit: [],
        sector: [],
        category: [],
        vatRate: [],
        country: [],
    }
}

export default (state = INITIAL_STATE, { type, payload }) => match(type, {
    SET_USER_PAGE_LANGUAGE: () => ({
        ...state,
        userLanguage: payload
    }),
    FETCH_PAGE_CONFIG_DICTIONARIES: () => ({
        ...state,
        dictionaries: {
            ...state.dictionaries,
            ...payload
        }
    }),
    FETCH_PAGE_CONFIG: () => ({
        ...state,
        languages: payload.languages
    }),
    FETCH_PAGE_LABELS_BY_LANGUAGE_CODE: () => ({
        ...state,
        labels: payload.reduce((json, value) => {
            json[value.key] = value.value;
            return json;
        }, {})
    }),
}, state);