import { match } from '../utils/match';

const INITIAL_STATE = {
    removeModal: {
        isShowing: false,
        id: ''
    },
    userModal: {
        isShowing: false,
        data: {}
    },
    inoviceModal: {
        isShowing: false,
        data: {}
    },
    inoviceCorrectedModal: {
        isShowing: false,
        data: {}
    },
    removeDateModal: {
        isShowing: false,
        data: {}
    },
    removeCorrectedDateModal: {
        isShowing: false,
        data: {}
    },
    rodoModal: {
        isShowing: false,
        data: {}
    },
    blockModal: {
        isShowing: false,
        data: {}
    },
    objectModal: {
        isShowing: false,
        gallery: []
    },
    instructionsModal: {
        isShowing: false,
    },
};

export default (state = INITIAL_STATE, { type, payload }) => match(type, {
    SHOW_REMOVE_MODAL: () => ({
        ...state,
        removeModal: {
            isShowing: true,
            id: payload
        },
    }),
    HIDE_REMOVE_MODAL: () => ({
        ...state,
        removeModal: {
            isShowing: false
        },
    }),
    SHOW_REMOVE_DATE_MODAL: () => ({
        ...state,
        removeDateModal: {
            isShowing: true,
            id: payload
        },
    }),
    HIDE_REMOVE_DATE_MODAL: () => ({
        ...state,
        removeDateModal: {
            isShowing: false
        },
    }),
    SHOW_REMOVE_CORRECTED_DATE_MODAL: () => ({
        ...state,
        removeCorrectedDateModal: {
            isShowing: true,
            id: payload
        },
    }),
    HIDE_REMOVE_CORRECTED_DATE_MODAL: () => ({
        ...state,
        removeCorrectedDateModal: {
            isShowing: false
        },
    }),
    SHOW_USER_MODAL: () => ({
        ...state,
        userModal: {
            isShowing: true,
            data: payload
        },
    }),
    SHOW_INSTRUCTIONS_MODAL: () => ({
        ...state,
        instructionsModal: {
            isShowing: true
        },
    }),
    HIDE_USER_MODAL: () => ({
        ...state,
        userModal: {
            isShowing: false
        },
    }),
    SHOW_INOVICE_MODAL: () => ({
        ...state,
        inoviceModal: {
            isShowing: true,
            data: payload
        },
    }),
    SHOW_INOVICE_CORRECTED_MODAL: () => ({
        ...state,
        inoviceCorrectedModal: {
            isShowing: true,
            id: payload
        },
    }),
    HIDE_INOVICE_MODAL: () => ({
        ...state,
        inoviceModal: {
            isShowing: false
        },
    }),
    HIDE_INOVICE_CORRECTED_MODAL: () => ({
        ...state,
        inoviceCorrectedModal: {
            isShowing: false
        },
    }),
    HIDE_INSTRUCTIONS_MODAL: () => ({
        ...state,
        instructionsModal: {
            isShowing: false
        },
    }),
    SHOW_RODO_MODAL: () => ({
        ...state,
        rodoModal: {
            isShowing: true,
            data: payload
        },
    }),
    HIDE_RODO_MODAL: () => ({
        ...state,
        rodoModal: {
            isShowing: false
        },
    }),
    SHOW_BLOCK_MODAL: () => ({
        ...state,
        blockModal: {
            isShowing: true,
            id: payload.id,
            locked: payload.locked
        },
    }),
    HIDE_BLOCK_MODAL: () => ({
        ...state,
        blockModal: {
            isShowing: false
        },
    }),
    SHOW_OBJECT_MODAL: () => ({
        ...state,
        objectModal: {
            isShowing: true,
        },
    }),
    HIDE_OBJECT_MODAL: () => ({
        ...state,
        objectModal: {
            isShowing: false
        },
    }),
}, state);
