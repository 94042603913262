import Raven from 'raven-js';

import initSentry from '../initSentry';

export default (error, extra = {}) => {
    initSentry();

    Raven.captureException(error, {
        extra: extra
    });
}
