import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import Loadable from 'react-loadable';
import { paths } from '@/constants/config';

import ScrollTop from '@/containers/ScrollTop';
import BodyClasses from '@/containers/BodyClasses';
import Preloader from '@/components/html/Preloader';
import Snackbar from '@/components/html/Snackbar';

const Home = Loadable({
    loader: () => import('@/containers/pages/Home'),
    loading: () => <Preloader active={false} />,
});

const DetailPages = Loadable({
    loader: () => import('@/containers/pages/LotDetails'),
    loading: () => <Preloader active={false} />
})
const FillPower = Loadable({
    loader: () => import('@/containers/pages/LotDetails/components/FillPower'),
    loading: () => <Preloader active={false} />
})
const Species = Loadable({
    loader: () => import('@/containers/pages/LotDetails/components/Species'),
    loading: () => <Preloader active={false} />
})
const Downtek = Loadable({
    loader: () => import('@/containers/pages/LotDetails/components/Downtek'),
    loading: () => <Preloader active={false} />
})

const Origin = Loadable({
    loader: () => import('@/containers/pages/LotDetails/components/Origin'),
    loading: () => <Preloader active={false} />
})


const Login = Loadable({
    loader: () => import('@/containers/pages/Account/Login'),
    loading: () => <Preloader active={false} />,
});

const codeLogin = Loadable({
    loader: () => import('@/containers/pages/Account/codeLogin'),
    loading: () => <Preloader active={false} />,
})

const RemindPassword = Loadable({
    loader: () => import('@/containers/pages/Account/RemindPassword'),
    loading: () => <Preloader active={false} />,
});

const ResetPassword = Loadable({
    loader: () => import('@/containers/pages/Account/ResetPassword'),
    loading: () => <Preloader active={false} />,
});

const Logout = Loadable({
    loader: () => import('@/containers/pages/Account/Logout'),
    loading: () => <Preloader active={false} />,
});

const Admin_Dashboard = Loadable({
    loader: () => import('@/containers/panels/admin/Dashboard'),
    loading: () => <Preloader active={false} />,
});

const Page404 = Loadable({
    loader: () => import('@/containers/Page404'),
    loading: () => <Preloader active={false} />,
});


const ProductsList = Loadable({
    loader: () => import('@/containers/pages/ProductsList'),
    loading: () => <Preloader active={false} />,
});


const DownSource = Loadable({
    loader: () => import('@/containers/pages/DownSource'),
    loading: () => <Preloader active={false} />,
});


const DownSourceAdd = Loadable({
    loader: () => import('@/containers/pages/DownSource/components/Add'),
    loading: () => <Preloader active={false} />,
});



const DownSourceEdit = Loadable({
    loader: () => import('@/containers/pages/DownSource/components/Edit'),
    loading: () => <Preloader active={false} />,
});



const ProductsListAdd = Loadable({
    loader: () => import('@/containers/pages/ProductsList/components/Add'),
    loading: () => <Preloader active={false} />,
});

const ProductsEdit = Loadable({
    loader: () => import('@/containers/pages/ProductsList/components/Edit'),
    loading: () => <Preloader active={false} />,
});
const ProductsForm = Loadable({
    loader: () => import('@/containers/pages/ProductForm'),
    loading: () => <Preloader active={false} />,
});

const ProductAddDetails = Loadable({
    loader: () => import('@/containers/pages/ProductsList/components/Details'),
    loading: () => <Preloader active={false} />
})




const ProductEditForm = Loadable({
    loader: () => import('@/containers/pages/ProductEditForm'),
    loading: () => <Preloader active={false} />,
});


const ProductEditList = Loadable({
    loader: () => import('@/containers/pages/ProductEditList'),
    loading: () => <Preloader active={false} />,
});


const FaqPage = Loadable({
    loader: () => import('@/containers/pages/Faq'),
    loading: () => <Preloader active={false} />,
});

class App extends Component {
    render() {
        return (
            <ScrollTop>
                <BodyClasses>
                    <Snackbar {...this.props} />
                    <Switch>
                        <Route exact path={paths.LOGIN} component={Login} />
                        <Route exact path={paths.HOME} component={Home} />
                        <Route exact path={paths.DETAILS_PAGES(':id')} component={DetailPages} />
                        <Route exact path={paths.LOT_FILL_POWER(':id')} component={FillPower} />
                        <Route exact path={paths.LOT_SPECIES(':id')} component={Species} />
                        <Route exact path={paths.LOT_ORIGIN(':id')} component={Origin} />
                        <Route exact path={paths.LOT_DOWNTEK(':id')} component={Downtek} />

                        <Route exact path={paths.LOGIN} component={Login} />
                        <Route exact path={paths.CODE_LOGIN} component={codeLogin} />
                        <Route exact path={paths.LOGOUT} component={Logout} />
                        <Route exact path={paths.REMIND_PASSWORD} component={RemindPassword} />
                        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
                        <Route exact path={paths.SET_PASSWORD} render={(routeProps) => (<ResetPassword {...routeProps} setPassword={true} />)} />

                        {/*ADMIN COMPANIES*/}
                        <Route exact path={paths.ADMIN_DASHBOARD} component={Admin_Dashboard} />

                        {/*ADMIN ADRIAN RYŚ*/}
                        <Route exact path={paths.ADMIN_DOWN_SOURCE} component={DownSource} />
                        <Route exact path={paths.ADMIN_DOWN_SOURCE_ADD} component={DownSourceAdd} />
                        <Route exact path={paths.ADMIN_DOWN_SOURCE_EDIT(':id')} component={DownSourceEdit} />
                        
                        <Route exact path={paths.ADMIN_PRODUCTS_ADD_DETAILS} component={ProductAddDetails} />

                        <Route exact path={paths.ADMIN_PRODUCTS} component={ProductsList} />
                        <Route exact path={paths.ADMIN_PRODUCTS_EDIT(':id')} component={ProductsEdit} />
                        {/* PO ZAORANIU*/}
                        <Route exact path={paths.ADMIN_PRODUCTS_ADD} component={ProductsListAdd} />
                        <Route exact path={paths.ADMIN_PRODUCT_FORM} component={ProductsForm} />
                        <Route exact path={paths.ADMIN_PRODUCT_EDIT_FORM} component={ProductEditList} />
                        <Route exact path={paths.FRONT_FAQ} component={FaqPage} />
                        {/*<Route exact path={paths.ADMIN_PRODUCT_EDIT_LIST} component={ProductEditList} />*/}

                        <Route component={Page404} />
                    </Switch>
                </BodyClasses>
            </ScrollTop>
        );
    }
}

export default App