import { match } from '@/utils/match';

const INITIAL_STATE = {
    product_by_lot:{}
}   

export default (state = INITIAL_STATE, { type, payload }) => match(type, {

    FETCH_PRODUCT_BY_LOT: () => ({
        ...state,
        product_by_lot: payload.data,
    }),
    
}, state);  