import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import styles from './snackbar.module.scss';
import classNames from 'classnames';

import {hideSnackbar} from '@/actions/snackbar';

class Snackbar extends Component {
    hideSnackbar = () => {
        this.props.hideSnackbar();
    };

    setTimeForDismiss = message => {
        return message ? message.length * 400 : 0;
    }

    snackbarTimeDismiss = snackbarMessage => {
        setTimeout(() => {
            this.hideSnackbar();
        }, this.setTimeForDismiss(snackbarMessage));
    };

    render() {
        const {snackbar} = this.props;

        if (snackbar.message && snackbar.message.length) {
            this.snackbarTimeDismiss(snackbar.message)
        };

        if (snackbar.isShowing) {
            return (
                <div className={classNames(styles.SnackbarComponent,{[styles.success]: snackbar.type === 'success'})}>
                    <span>
                        {snackbar.message.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
                    </span>
                    <button
                        className={styles.close}
                        aria-label="Close snackbar"
                        onClick={() => this.hideSnackbar()}
                    >
                        x
                    </button>
                </div>
            )
        }

        return null
    }
}


const mapStateToProps = state => ({
    snackbar: state.snackbar.snackbar,
});

const mapDispatchToProps = dispatch => ({
    hideSnackbar: bindActionCreators(hideSnackbar, dispatch),

});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);

