import { match } from '@/utils/match';

const INITIAL_STATE = {
    downSource: {}
}

export default (state = INITIAL_STATE, { type, payload }) => match(type, {

    FETCH_DOWN_SOURCE: () => ({
        ...state,
        downSource: payload.data
    })
}, state);