import { match } from '@/utils/match';
import { sortBy } from 'lodash';

const INITIAL_STATE = {
    products: {},
    originList: {},
    downSourceList: {},
    brandList : {},
    product: {},
    productLot: {},
    certificatesValues: {},
    certificatesValuesSub: {},
    technologyValues: {},
    productByWord:{},
    testingReport:{}
}

export default (state = INITIAL_STATE, { type, payload }) => match(type, {


    FETCH_TESTING_REPORT: () => ({
        ...state,
        testingReport: payload.data
    }),



    FETCH_TECHNOLOGY_VALUES: () => ({
        ...state,
        technologyValues: payload.data
    }),



    FETCH_FIND_PRODUCT_BY_WORD: () => ({
        ...state,
        productByWord: payload.data
    }),


    FETCH_CERTIFICATES_VALUES: () => ({
        ...state,
        certificatesValues: payload.data
    }),

    FETCH_CERTIFICATES_VALUES_SUB: () => ({
        ...state,
        certificatesValuesSub: payload.data
    }),

    FETCH_PRODUCT_LOT: () => ({
        ...state,
        productLot: payload.data
    }),

    FETCH_PRODUCTS: () => ({
        ...state,
        products: payload.data
    }),


    FETCH_ORIGIN_LIST: () => ({
        ...state,
        originList: payload.data
    }),



    FETCH_PRODUCT_DOWN_SOURCE: () => ({
        ...state,
          downSourceList: sortBy(payload.data, 'name').reverse()
    }),


    FETCH_PRODUCT_BRAND: () => ({
        ...state,
        brandList: payload.data
    }),

    FETCH_PRODUCT: () => ({
        ...state,
        product: payload.data
    }),


}, state);
