import has from 'lodash/has';

import captureException from '../captureException';

export default error => {
    has(error, 'config.headers.Authorization')
    error.config.headers.Authorization = error.config.headers.Authorization && error.config.headers.Authorization.split(' ')[0].concat(" ***TOKEN***");
    captureException(`[${error.config.method}] (${error.status || error.response.status}) ${error.request.responseURL}`, {
        data: error.data,
        status: error.status || error.response.status,
        headers_config: error.config.headers,
        method: error.config.method,
        headers_response: error.headers,
        error_object: error,
    });
}
