import { match } from '@/utils/match';

const INITIAL_STATE ={
    isAuthenticated: false,
    tokenIsValid: false,
    user: {},
    QRCode:{}
}

export default (state = INITIAL_STATE, { type, payload }) => match(type, {
    SET_CURRENT_USER: () => ({
		...state,
        isAuthenticated: true,
    }),
    SET_CURRENT_QRCODE:() => ({
        ...state,
        QRCode: payload.data,
    }),
}, state);