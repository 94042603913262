import { snackbar as types } from '@/constants/actionNames';


export const showSnackbar = (message, type) => dispatch => {
    dispatch({
        type: types.SHOW_SNACKBAR,
        payload: {message, type}
    })
}

export const hideSnackbar = () => dispatch => {
    dispatch({
        type: types.HIDE_SNACKBAR,
    })
}