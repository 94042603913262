import { match } from '@/utils/match';

const INITIAL_STATE = {
	dashboard:  { data: {numberOfConsumedCredits:[],numberOfCredits:[],lastUser:[]}},
	dashboardCredits: { data: [] },
	dashboardCompanies: { data: [] },
	dashboardPartners: { data: [] },
	dashboardCountersPartners: false,
	dashboardCountersCompanies: false,
	dashboardEntries: []
}

export default (state = INITIAL_STATE, { type, payload }) => match(type, {
	FETCH_DASHBOARD: () => ({
		...state,
		dashboard: payload
	}),
	FETCH_DASHBOARD_COMPANIES: () => ({
		...state,
		dashboardCompanies: payload
	}),
	FETCH_DASHBOARD_PARTNERS: () => ({
		...state,
		dashboardPartners: payload
	}), FETCH_DASHBOARD_COUNTERS_COMPANY: () => ({
		...state,
		dashboardCountersCompanies: payload.headers['x-total']
	}),
	FETCH_DASHBOARD_COUNTERS_PARTNER: () => ({
		...state,
		dashboardCountersPartners: payload.headers['x-total']
	}),
	FETCH_DASHBOARD_ENTRIES: () => ({
		...state,
		dashboardEntries: payload
	}),
	FETCH_DASHBOARD_CREDITS: () => ({
		...state,
		dashboardCredits: payload
	}),

}, state);