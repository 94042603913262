import {combineReducers} from 'redux';
import {reducer as FormReducer} from 'redux-form';
import authReducer from './authReducer';
import dashboardReducer from './panelAdmin/dashboardReducer';
import usersReducer from './panelAdmin/usersReducer';
import modalsReducer from './reducerModals';
import snackbarReducer from './reducerSnackbar';
import productsReducer from './dashboard/productsReducer';
import downSourceReducer from './dashboard/sourceDownReducer';
import originListReducer from './dashboard/productsReducer';
import configReducer from './configReducer';
///// po zaoraniu
import productFormReducer from './ProductForm/productFormReducer';
import frontReducer from './frontReducer'


import {auth} from '@/constants/actionNames'

const appReducer = combineReducers({
    auth: authReducer,
    form: FormReducer,
    config: configReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    modals: modalsReducer,
    snackbar: snackbarReducer,
    products: productsReducer,
    downSource : downSourceReducer,
    originList: originListReducer,
    front:frontReducer,
    productForm: productFormReducer
});

const rootReducer = (state, action) => {
    if (action.type === auth.LOGOUT) {
        state = {
            config: {...state.config}
        }
    }
    return appReducer(state, action)
}

export default rootReducer;