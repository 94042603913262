import { match } from '@/utils/match';

const INITIAL_STATE = {
    productForm: {},
    productBrand: {},
    productSpacies: {},
    productOrigin: {},
    productByLot:{}
}

export default (state = INITIAL_STATE, { type, payload }) => match(type, {


    FETCH_PRODUCT_LOT: () => ({
        ...state,
        productByLot: payload.data
    }),



    FETCH_PRODUCT_BRAND: () => ({
        ...state,
        productBrand: payload.data
    }),


    FETCH_PRODUCT_SPECIES: () => ({
        ...state,
        productSpacies: payload.data
    }),

    FETCH_PRODUCT_ORIGIN: () => ({
        ...state,
        productOrigin: payload.data
    }),


}, state);