import {createConstants} from '@/utils/createConstants';

export const auth = createConstants(
    'SET_CURRENT_USER',
    'LOGOUT',
    'SET_CURRENT_QRCODE',
    'CHECK_VALID_TOKEN_SUCCESS',
    'CHECK_VALID_TOKEN_FAIL'
);

export const errors = createConstants(
    'GET_ERRORS',
);


export const dashboard = createConstants(
    'FETCH_DASHBOARD',
    'FETCH_DASHBOARD_COMPANIES',
    'FETCH_DASHBOARD_PARTNERS',
    'FETCH_DASHBOARD_COUNTERS_COMPANY',
    'FETCH_DASHBOARD_COUNTERS_PARTNER',
    'FETCH_DASHBOARD_ENTRIES',
    'FETCH_DASHBOARD_CREDITS'
);

export const users = createConstants(
    'FETCH_USERS',
    'FETCH_USER',
    'FETCH_GROUP',
    'FETCH_GROUP_USERS',
    'FETCH_ENTRY_INITIAL_DATA',
    'FETCH_ACCESS_SETTINGS',
);



export const config = createConstants(
    'SET_USER_PAGE_LANGUAGE',
    'FETCH_PAGE_CONFIG',
);


export const modals = createConstants(
    'SHOW_REMOVE_MODAL',
    'HIDE_REMOVE_MODAL',
    'SHOW_USER_MODAL',
    'HIDE_USER_MODAL',
    'SHOW_INOVICE_MODAL',
    'HIDE_INOVICE_MODAL',
    'SHOW_REMOVE_DATE_MODAL',
    'HIDE_REMOVE_DATE_MODAL',
    'SHOW_RODO_MODAL',
    'HIDE_RODO_MODAL',
    'SHOW_BLOCK_MODAL',
    'HIDE_BLOCK_MODAL',
    'SHOW_OBJECT_MODAL',
    'HIDE_OBJECT_MODAL',
    'SHOW_INOVICE_CORRECTED_MODAL',
    'HIDE_INOVICE_CORRECTED_MODAL',
    'SHOW_REMOVE_CORRECTED_DATE_MODAL',
    'HIDE_REMOVE_CORRECTED_DATE_MODAL',
    'SHOW_INSTRUCTIONS_MODAL',
    'HIDE_INSTRUCTIONS_MODAL',
);

export const admins = createConstants(
    'FETCH_ADMINS',
    'FETCH_ADMIN'
);

export const snackbar = createConstants(
    'SHOW_SNACKBAR',
    'HIDE_SNACKBAR'
)



/*********************************************************************************************************/
export const products = createConstants(
    'FETCH_PRODUCTS',
    'FETCH_ORIGIN_LIST',
    'FETCH_PRODUCT_DOWN_SOURCE',
    'FETCH_PRODUCT',
    'FETCH_PRODUCT_BRAND',
    'FETCH_PRODUCT_LOT',
    'FETCH_CERTIFICATES_VALUES',
    'FETCH_FIND_PRODUCT_BY_WORD',
    'FETCH_CERTIFICATES_VALUES_SUB',
    'FETCH_TECHNOLOGY_VALUES',
    'FETCH_TESTING_REPORT',
);

export const downSource = createConstants(
    'FETCH_DOWN_SOURCE'
);
// po zaoraniu
export const productForm = createConstants(
    'FETCH_PRODUCT_FORM',
    'FETCH_PRODUCT_BRAND',
    'FETCH_PRODUCT_SPECIES',
    'FETCH_PRODUCT_ORIGIN'
);

export const front = createConstants(
    'FETCH_PRODUCT_BY_LOT'
)