import {match} from '@/utils/match';

const INITIAL_STATE = {
    users: [],
    user: {},
    group:{},
    groupUsers:[],
    currentPage: {},
    pageLimit: {},
    entry:{},
    accessSettings:{}
}

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    FETCH_USERS: () => ({
        ...state,
        users: payload.data,
        currentPage: payload.headers['x-current-page'],
        pageLimit: Math.ceil(payload.headers['x-total'] / payload.headers['x-limit']) ,
    }),
    FETCH_USER: () => ({
        ...state,
        user: payload
    }),
    FETCH_GROUP: () => ({
        ...state,
        group: payload
    }),
    FETCH_GROUP_USERS: () => ({
        ...state,
        groupUsers: payload
    }),
    FETCH_ENTRY_INITIAL_DATA: () => ({
        ...state,
        entry: payload
    }),
    FETCH_ACCESS_SETTINGS: () => ({
        ...state,
        accessSettings: payload
    }),
}, state);