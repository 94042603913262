import {match} from '../utils/match';

const INITIAL_STATE = {
    snackbar: {
        isShowing: false,
        message: '',
        type: ''
    },
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    SHOW_SNACKBAR: () => ({
        ...state,
        snackbar: {
            isShowing: true,
            message: payload.message,
            type: payload.type
        },
    }),
    HIDE_SNACKBAR: () => ({
        ...state,
        snackbar: {
            isShowing: false,
            message: '',
            type: ''
        },
    }),
}, state);
