import React, { Component } from 'react';

import styles from './preloader.module.scss'

import classNames from 'classnames'
import { connect } from "react-redux";

class Preloader extends Component {

    render() {
        return (
            <aside
                className={classNames(
                    styles.preloader,
                    { [styles.active]: true }, //this.props.active
                    { [styles.absolute]: this.props.absolute },
                    { [styles.admin]: this.props.userRole === 'admin' }
                )}
            >
                <div className={classNames(styles.content, 'container')}>
                    <div className={styles.header}>
                        <img className={styles.desktop} src='/img/logoWhite.png' alt="Sustainable" />
                        <img className={styles.mobile} src='/img/logoWhiteMobile.png' alt="Sustainable" />
                    </div>
                    <div className={styles.progress}>
                        <div className={styles.dots}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <span>Generating Data</span>
                    </div>
                </div>
            </aside>
        )
    }
}

const mapStateToProps = state => ({
    userRole: state.auth.user.roleRequest
})

export default connect(mapStateToProps, null)(Preloader);