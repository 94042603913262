import Raven from 'raven-js';
import { SENTRY_DSN } from '@/constants/config';

export default () => {
    if (!Raven.isSetup()) {
        Raven.config(SENTRY_DSN, {
            // environment: ENV,
            // ignoreUrls: [/^http:\/\/localhost:3000/]
        }).install();
    }
}